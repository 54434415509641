import { StripeSessionLink, StripeSubscriptionData } from './types';
import useFetch from './useFetch';

/**
 * Custom hook to create a Stripe Checkout session.
 *
 * @returns {Object} - An object containing:
 *  - createCheckoutSession: Function to initiate the checkout session creation.
 *  - checkouLoading: Boolean indicating if the request is in progress.
 *  - checkoutLink: The URL for the checkout session.
 *  - responseCode: The HTTP response code from the request.
 */
export const useCreateCheckoutSession = () => {
  const { commonFetch,
    isLoading: checkoutLoading,
    data: checkoutLink,
    responseCode } = useFetch<StripeSessionLink>({
    endpoint: '/subscription/checkout',
    method: 'POST',
  });

  const createCheckoutSession = (input?: undefined) => commonFetch({ input });
  return { createCheckoutSession, checkoutLoading, checkoutLink, responseCode };
};

export const useCreateManageBillingSession = () => {
  const { commonFetch,
    isLoading: manageBillingLoading,
    data: manageLink,
    responseCode } = useFetch<StripeSessionLink>({
    endpoint: '/subscription/manage',
    method: 'POST',
  });

  const createManageBillingSession = (input?: undefined) => commonFetch({ input });
  return { createManageBillingSession, manageBillingLoading, manageLink, responseCode };
};

/**
 * Custom hook to synchronize Stripe subscription data.
 *
 * @returns {Object} - An object containing:
 *  - syncStripeData: Function to initiate the data synchronization.
 *  - syncLoading: Boolean indicating if the request is in progress.
 *  - subData: The subscription data from Stripe.
 *  - responseCode: The HTTP response code from the request.
 */
export const useSyncStripeData = () => {
  const { commonFetch,
    isLoading: syncLoading,
    data: subData,
    responseCode } = useFetch<StripeSubscriptionData>({
    endpoint: '/subscription/sync',
    method: 'POST',
  });

  const syncStripeData = (input?: undefined) => commonFetch({ input });
  return { syncStripeData, syncLoading, subData, responseCode };
};
