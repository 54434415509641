import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogOut, Search, Layout, Settings, Menu } from 'lucide-react';
import useSignOut from '../../utils/hooks/useSignOut';

const navItems = [
  {
    label: 'Search',
    icon: Search,
    path: '/search',
  },
  {
    label: 'Dashboard',
    icon: Layout,
    path: '/dashboard',
  },
  {
    label: 'Settings',
    icon: Settings,
    path: '/settings',
  },
] as const;

function Header() {
  const { logOut } = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path: string) => {
    if (path === 'logout') {
      logOut().catch((error) => {
        console.error('Error signing out:', error);
      });
      return;
    }
    navigate(path);
  };

  return (
    <header className="fixed top-0 left-0 right-0 h-20 bg-gray-800/95 backdrop-blur-sm border-b border-gray-700/50 z-50">
      <div className="h-full max-w-6xl mx-auto px-4 flex items-center">
        {/* Logo */}
        <div className="flex items-center mr-8">
          <img
            src="/golf_bot_image.jpeg"
            alt="ForeAdvantage"
            className="w-12 h-12 rounded-xl shadow-lg"
          />
        </div>

        {/* Navigation */}
        <nav className="hidden md:flex items-center space-x-2">
          {navItems.map(({ label, icon: Icon, path }) => {
            const isActive = location.pathname === path;
            return (
              <button
                key={path}
                onClick={() => handleNavigation(path)}
                className={`
                  px-4 py-2 rounded-lg flex items-center space-x-2 
                  transition-all duration-200
                  ${
                    isActive
                      ? 'bg-yellow-500 text-gray-900'
                      : 'text-gray-400 hover:text-gray-200 hover:bg-gray-700/50'
                  }
                `}
                type="button"
              >
                <Icon
                  className={`
                    w-5 h-5 transition-transform 
                    ${
                      isActive
                        ? 'text-gray-900'
                        : 'text-gray-400 group-hover:text-yellow-500'
                    }
                  `}
                />
                <span className="font-medium">{label}</span>
              </button>
            );
          })}
        </nav>

        {/* Sign Out Button */}
        <button
          onClick={() => handleNavigation('logout')}
          className="ml-auto hidden md:flex px-4 py-2 rounded-lg items-center space-x-2
            text-gray-400 hover:text-red-400 hover:bg-red-500/10
            transition-all duration-200"
          type="button"
        >
          <LogOut className="w-5 h-5" />
          <span className="font-medium">Sign Out</span>
        </button>

        {/* Mobile Menu Button */}
        <button
          onClick={() => {
            /* Toggle mobile menu */
          }}
          className="ml-auto md:hidden p-2 rounded-lg text-gray-400
            hover:text-gray-200 hover:bg-gray-700/50"
          type="button"
        >
          <Menu
            className="w-6 h-6"
            aria-label="Show menu"
          />
        </button>
      </div>

      {/* Optional: Subtle gradient overlay */}
      <div
        className="absolute inset-x-0 -bottom-px h-px bg-gradient-to-r
        from-transparent via-yellow-500/20 to-transparent"
      />
    </header>
  );
}

export default Header;
