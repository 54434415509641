import React, { useEffect, useState } from 'react';
import { X, Copy, Check } from 'lucide-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaDiscord } from 'react-icons/fa6';
import { useCreateVerificationCode } from '../../utils/api/requests';
import Spinner from '../utils/Spinner';
import useAuthContext from '../../utils/hooks/useAuthContext';

function VerificationCharacter({ char }: { char: string }) {
  return (
    <div className="flex flex-col items-center justify-end w-8 h-10">
      <span className="text-2xl font-bold text-white mb-1">{char}</span>
      <div className="h-0.5 w-full bg-gray-400" />
    </div>
  );
}

function VerificationCode({ code }: { code: string }) {
  const chars = code ? code.split('') : Array(6).fill('');

  return (
    <div className="flex justify-center space-x-2">
      {chars.map((char) => (
        <VerificationCharacter
          key={`char-${Math.random()}`}
          char={char}
        />
      ))}
    </div>
  );
}

interface DiscordVerificationPopupProps {
  closeWindow: () => void;
}

export default function DiscordVerificationPopup({
  closeWindow,
}: DiscordVerificationPopupProps) {
  const [displayedCode, setDisplayedCode] = useState('');
  const [hasCopied, setHasCopied] = useState(false);
  const { createVerificationCode, verificationCode, verificationCodeLoading } =
    useCreateVerificationCode();
  const { forceAuthRefresh } = useAuthContext();
  useEffect(() => {
    if (verificationCode) {
      setDisplayedCode(verificationCode.verification_code);
      console.log(verificationCode.verification_code);
      setHasCopied(false);
    }
  }, [verificationCode]);

  const handleCloseWindow = () => {
    forceAuthRefresh();
    closeWindow();
  };
  return (
    <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-gray-800 rounded-xl shadow-xl w-full max-w-md p-6 relative">
        {/* Close Button */}
        <button
          onClick={handleCloseWindow}
          className="absolute top-4 right-4 p-2 rounded-lg text-gray-400
            hover:text-gray-300 hover:bg-gray-700/50 transition-colors"
          type="button"
        >
          <X
            className="w-5 h-5"
            aria-label="close"
          />
        </button>

        <div className="space-y-8">
          {/* Header */}
          <div className="text-center">
            <FaDiscord className="w-16 h-16 text-[#5865F2] mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-white mb-2">Connect Discord</h2>
            <a
              href="https://github.com/cgorski03/foreup-bot-webapp/blob/main/instructions/connect_discord.md"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-400 hover:text-gray-300 underline"
            >
              View Instructions
            </a>
          </div>

          {/* Join Server Button */}
          <div className="text-center">
            <a
              href="https://discord.gg/HcSbFT2tzM"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 bg-[#5865F2] hover:bg-[#4752C4]
                text-white font-medium rounded-lg transition-colors"
            >
              Join Server
            </a>
          </div>

          {/* Verification Code Section */}
          <div className="space-y-4">
            <h3 className="text-white font-medium text-center">Your Verification Code</h3>

            <VerificationCode code={displayedCode} />

            <div className="flex space-x-2">
              <button
                onClick={() => {
                  createVerificationCode();
                }}
                disabled={verificationCodeLoading}
                className="flex-1 px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg
                  hover:bg-yellow-400 transition-colors font-medium disabled:opacity-50
                  disabled:cursor-not-allowed flex items-center justify-center"
                type="button"
              >
                {verificationCodeLoading ? <Spinner /> : 'Generate Code'}
              </button>

              <CopyToClipboard
                text={displayedCode}
                onCopy={() => setHasCopied(true)}
              >
                <button
                  type="button"
                  className="p-2 bg-gray-700 rounded-lg text-gray-300
                    hover:bg-gray-600 transition-colors"
                >
                  {hasCopied ? (
                    <Check className="w-5 h-5 text-green-500" />
                  ) : (
                    <Copy className="w-5 h-5" />
                  )}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
