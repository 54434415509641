import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Spinner from '../../components/utils/Spinner';
import { useCreateCheckoutSession } from '../../utils/api/stripe-requests';

function Subscribe() {
  const { createCheckoutSession, checkoutLoading, checkoutLink, responseCode } =
    useCreateCheckoutSession();

  const handleSubscribe = async () => {
    try {
      await createCheckoutSession();
    } catch (err) {
      console.error('Checkout error:', err);
    }
  };

  useEffect(() => {
    if (checkoutLink?.url) {
      window.location.href = checkoutLink.url;
    }
  }, [checkoutLink]);
  // Handle error states
  const error =
    responseCode && responseCode >= 400 ? 'Failed to create checkout session' : null;

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-black py-4 px-6 sticky top-0 shadow-lg">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/"
            className="flex items-center group"
          >
            <ArrowLeft className="w-5 h-5 text-yellow-500 mr-2 transition-transform group-hover:-translate-x-1" />
            <span className="text-yellow-500 text-sm">Back to Home</span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-sm border border-gray-700">
          <div className="flex items-center justify-center mb-6">
            <img
              src="golf_bot_image.jpeg"
              alt="ForeAdvantage Golf Logo"
              className="w-16 h-16 rounded-full border-2 border-yellow-500"
            />
          </div>

          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-white mb-2">Premium Subscription</h1>
            <div className="text-4xl font-bold text-white">
              ${process.env.REACT_APP_SUBSCRIPTION_PRICE}
              <span className="text-lg text-gray-400 ml-1">/month</span>
            </div>
          </div>

          {error && (
            <div className="bg-red-500/10 border border-red-500/50 text-red-500 px-4 py-3 rounded-lg mb-6 text-sm">
              {error}
            </div>
          )}

          <button
            type="button"
            onClick={handleSubscribe}
            disabled={checkoutLoading}
            className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg
              hover:bg-yellow-400 transition-all font-medium disabled:opacity-50
              disabled:cursor-not-allowed flex items-center justify-center"
          >
            {checkoutLoading ? <Spinner /> : 'Checkout Now'}
          </button>

          <p className="mt-6 text-center text-gray-400 text-sm">
            You can cancel your subscription at any time
          </p>
        </div>
      </main>

      <footer className="bg-black py-4 text-center text-gray-400">
        <p className="text-sm">
          By subscribing, you agree to our{' '}
          <Link
            to="/terms"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Privacy Policy
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default Subscribe;
