import { useContext, useState } from 'react';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { UserInformation, UserInformationContext } from '../../Contexts/UserContext';
import getIdToken from '../authFunctions/getIdToken';

export default function useAuthContext() {
  const emptyUserInformation = {
    sub: undefined,
    name: undefined,
    email: undefined,
    emailVerified: undefined,
    idToken: undefined,
    channelId: undefined,
    subscribedUntil: undefined,
    subscriptionStatus: undefined,
    cancelAtSubscriptionEnd: undefined,
    picture: undefined,
  };
  // Create loading state variables
  const [authContextLoading, setAuthContextLoading] = useState<boolean>(false);
  const [clearAuthContextLoading, setClearAuthContextLoading] = useState<boolean>(false);
  // Get the user information context
  const { setUserInfo } = useContext(UserInformationContext);
  const setAuthedContext = async (): Promise<UserInformation> => {
    setAuthContextLoading(true);
    try {
      const userAttributes = await fetchUserAttributes();
      console.log('User attributes:', userAttributes);
      // Check if the user has a channel id
      // This prevents an error if the user does has not setup discord yet
      const userInfo = {
        sub: userAttributes.sub,
        name: userAttributes.name,
        email: userAttributes.email,
        emailVerified: userAttributes.email_verified,
        idToken: await getIdToken(),
        picture: userAttributes.picture ?? undefined,
        channelId: userAttributes['custom:channel_id'] ?? undefined,
        subscribedUntil: userAttributes['custom:subscriptionEnd'] ?? undefined,
        subscriptionStatus: userAttributes['custom:subscriptionStatus'] ?? undefined,
        cancelAtSubscriptionEnd: userAttributes['custom:cancelAtPeriodEnd'] ?? undefined,
      };
      setUserInfo(userInfo);
      setAuthContextLoading(false);
      return userInfo;
    } catch (error: any) {
      setAuthContextLoading(false);
      return emptyUserInformation;
    }
  };
  const forceAuthRefresh = async (): Promise<UserInformation> => {
    setAuthContextLoading(true);
    try {
      await fetchAuthSession({ forceRefresh: true });
      return await setAuthedContext();
    } catch (error: any) {
      setAuthContextLoading(false);
      return emptyUserInformation;
    }
  };
  const clearAuthedContext = () => {
    setClearAuthContextLoading(true);
    try {
      setUserInfo(emptyUserInformation);
      return true;
    } catch (error: any) {
      return false;
    }
  };
  return { setAuthedContext,
    clearAuthedContext,
    forceAuthRefresh,
    authContextLoading,
    clearAuthContextLoading };
}
