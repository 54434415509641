import { createContext } from 'react';

export type UserInformation = {
  sub: string | undefined;
  name: string | undefined;
  email: string | undefined;
  emailVerified: string | undefined;
  idToken: string | undefined;
  channelId: string | undefined;
  subscriptionStatus: string | undefined;
  subscribedUntil: string | undefined;
  cancelAtSubscriptionEnd: string | undefined;
  picture: string | undefined;
};
type UserContextType = {
  userInfo: UserInformation | null;
  setUserInfo: (userInfo: UserInformation) => void;
};
export const UserInformationContext = createContext<UserContextType>({
  userInfo: null,
  setUserInfo: () => {},
});
