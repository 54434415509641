import React, { useEffect, useState } from 'react';
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Eye, EyeOff, ArrowLeft, Mail, Lock } from 'lucide-react';
import { FcGoogle } from 'react-icons/fc';
import Spinner from '../../components/utils/Spinner';
import useAuthContext from '../../utils/hooks/useAuthContext';

function Login() {
  const navigate = useNavigate();
  const { setAuthedContext } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [checkingUserAuth, setCheckingUserAuth] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const location = useLocation();
  // Check auth status on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const result = await setAuthedContext();
        if (result.sub) {
          // User is authenticated, redirect to dashboard
          navigate('/search');
        }
      } catch (err: any) {
        console.error('Auth check failed:', error);
      } finally {
        setCheckingUserAuth(false);
      }
    };

    checkAuth();
  }, []);

  const handleSocialSignIn = async (chosenProvider: 'Google' | 'Apple') => {
    try {
      await signInWithRedirect({ provider: chosenProvider });
    } catch (err: any) {
      console.log(err);
      setError(`Error signing in with ${chosenProvider}`);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    if (location.state?.message) {
      window.history.replaceState({}, document.title);
    }

    try {
      const response = await signIn({
        username: formData.email,
        password: formData.password,
      });

      if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate('/reset-password');
        return;
      }
      if (response.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        navigate('/confirm-email', {
          state: {
            email: formData.email,
            mode: 'login',
          },
        });
      }

      const result = await setAuthedContext();
      if (result) {
        navigate('/search');
      }
    } catch (err: any) {
      setError(err.message || 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (location.state?.message) {
      window.history.replaceState({}, document.title);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // Show loading state or nothing while checking auth
  if (checkingUserAuth) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-black py-4 px-6 sticky top-0 shadow-lg">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/"
            className="flex items-center group"
          >
            <ArrowLeft className="w-5 h-5 text-yellow-500 mr-2 transition-transform group-hover:-translate-x-1" />
            <span className="text-yellow-500 text-sm">Back to Home</span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-sm border border-gray-700">
          <div className="flex items-center justify-center mb-6">
            <img
              src="golf_bot_image.jpeg"
              alt="ForeAdvantage Golf Logo"
              className="w-12 h-12 rounded-full border-2 border-yellow-500"
            />
          </div>

          <h1 className="text-2xl font-bold text-white text-center mb-6">Welcome Back</h1>
          {location.state?.message && (
            <div className="bg-green-500/10 border border-green-500/50 text-green-500 px-4 py-3 rounded-lg mb-6 text-sm">
              {location.state?.message}
            </div>
          )}
          {error && (
            <div className="bg-red-500/10 border border-red-500/50 text-red-500 px-4 py-3 rounded-lg mb-6 text-sm">
              {error}
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className="space-y-4"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Email Address
                <div className="relative mt-1">
                  <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
                  <input
                    id="email"
                    name="email"
                    required
                    className="w-full pl-9 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="you@example.com"
                  />
                </div>
              </label>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Password
                <div className="relative mt-1">
                  <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    className="w-full pl-9 pr-10 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="••••••••"
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400  hover:text-gray-300"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                  </button>
                </div>
              </label>
            </div>

            <div className="flex justify-end">
              <Link
                to="/forgot-password"
                className="text-sm text-yellow-500 hover:text-yellow-400"
              >
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isLoading ? <Spinner /> : 'Sign In'}
            </button>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-600" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-800 text-gray-400">or continue with</span>
              </div>
            </div>

            <div className="space-y-3">
              <button
                type="button"
                onClick={() => handleSocialSignIn('Google')}
                className="w-full px-4 py-2 bg-white hover:bg-gray-100 text-gray-900 rounded-lg transition-colors flex items-center justify-center space-x-2"
              >
                <FcGoogle className="w-5 h-5" />
                <span className="font-medium">Google</span>
              </button>
            </div>
          </form>

          <p className="mt-6 text-center text-gray-400 text-sm">
            Don&apos;t have an account?{' '}
            <Link
              to="/signup"
              className="text-yellow-500 hover:text-yellow-400"
            >
              Sign up
            </Link>
          </p>
        </div>
      </main>

      <footer className="bg-black py-4 text-center text-gray-400">
        <p className="text-sm">
          By signing in, you agree to our{' '}
          <Link
            to="/terms"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Privacy Policy
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default Login;
