import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/utils/Spinner';
import { useSyncStripeData } from '../../utils/api/stripe-requests';
import useAuthContext from '../../utils/hooks/useAuthContext';

export default function Success() {
  const navigate = useNavigate();
  const { syncStripeData, syncLoading, subData } = useSyncStripeData();
  const [showError, setShowError] = useState(false);
  const { forceAuthRefresh } = useAuthContext();

  useEffect(() => {
    syncStripeData();
  }, []);

  useEffect(() => {
    if (!subData) return;

    const handleSubscriptionData = async () => {
      try {
        if (subData.status === 'active') {
          await forceAuthRefresh();
          navigate('/search');
        } else {
          setShowError(true);
        }
      } catch (error) {
        console.error('Error handling subscription data:', error);
        setShowError(true);
      }
    };
    handleSubscriptionData();
  }, [subData, navigate, forceAuthRefresh]);

  // Early return for loading state
  if (syncLoading || (!showError && !subData)) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900">
        <Spinner />
        <p className="mt-4 text-gray-300">Verifying subscription data...</p>
      </div>
    );
  }

  // Show error message if there's a problem
  if (showError) {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
        <div className="max-w-md text-center">
          <h1 className="text-2xl font-bold text-yellow-500 mb-4">
            Payment Verification Issue
          </h1>
          <div className="bg-gray-800 border border-gray-700 rounded-lg p-4 mb-4">
            <p className="text-gray-300 mb-3">
              We&apos;re having trouble verifying your payment. This could be temporary.
            </p>
            <p className="text-gray-300 mb-3">Please try the following:</p>
            <ol className="text-left list-decimal pl-5 mb-3 text-gray-300">
              <li className="mb-2">
                Check your email for payment confirmation from Stripe
              </li>
              <li className="mb-2">Refresh this page to attempt verification again</li>
              <li>
                If the issue persists, please contact our support team at{' '}
                <a
                  href="mailto:support@foreadvantagegolf.com"
                  className="text-yellow-500 hover:text-yellow-400 underline"
                >
                  support@foreadvantagegolf.com
                </a>
              </li>
            </ol>
          </div>
          <button
            onClick={() => window.location.reload()}
            className="bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-semibold py-2 px-4 rounded-lg transition-colors"
            type="button"
          >
            Refresh Page
          </button>
        </div>
      </div>
    );
  }

  // Show success message before redirect
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900">
      <h1 className="text-2xl font-bold text-yellow-500">Redirecting to dashboard</h1>
    </div>
  );
}
