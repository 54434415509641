/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Calendar,
  Clock,
  Search,
  Users,
  MapPin,
  Bell,
  Check,
  Flag,
  X,
  Menu,
  ArrowRight,
  Mail,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { FaGithub } from 'react-icons/fa6';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FeatureCard } from '../../components/landing/UICards';
import { useGetCourses } from '../../utils/api/requests';
import Spinner from '../../components/utils/Spinner';
import CheckSupportedCourses from './CheckSupportedCourses';

interface HeaderProps {
  smoothScroll: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  handleRedirect: (page: string) => void;
}

function Header({ smoothScroll, handleRedirect }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (): void => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="bg-black py-4 px-6 sticky top-0 z-40 shadow-lg">
      <div className="mx-auto flex items-center justify-between relative">
        <div className="flex items-center">
          <img
            src="golf_bot_image.jpeg"
            alt="ForeAdvantage Golf Logo"
            className="w-12 h-12 rounded-full border-2 border-yellow-500 object-cover"
          />
          <h1 className="text-2xl ml-4 font-bold text-yellow-500 hidden md:block">
            ForeAdvantage Golf
          </h1>
        </div>

        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white"
            type="button"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        <nav className="hidden md:block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <ul className="flex space-x-8">
            {['supported-courses', 'how-it-works', 'pricing'].map((section) => (
              <li
                className="text-gray-300 hover:text-yellow-500 transition-colors duration-200"
                key={section}
              >
                <a
                  href={`#${section}`}
                  onClick={(e: any) => smoothScroll(e)}
                >
                  {section
                    .split('-')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <div className="hidden md:flex space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-yellow-500 border border-yellow-500 rounded-lg hover:bg-yellow-500 hover:text-gray-900 transition-colors duration-200"
            onClick={() => handleRedirect('login')}
          >
            Log In
          </button>
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-colors duration-200"
            onClick={() => handleRedirect('signup')}
          >
            Sign Up
          </button>
        </div>

        {/* Mobile menu */}
        <div
          className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden absolute top-full left-0 right-0 bg-black mt-4 py-4 px-6`}
        >
          <nav className="mb-4">
            <ul className="flex flex-col space-y-2">
              {['how-it-works', 'features', 'check-supported-courses'].map((section) => (
                <li
                  className="text-gray-300 hover:text-yellow-500 transition-colors duration-200"
                  key={section}
                >
                  <a
                    href={`#${section}`}
                    onClick={(e: any) => {
                      smoothScroll(e);
                      toggleMenu();
                    }}
                  >
                    {section
                      .split('-')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="flex flex-col space-y-2">
            <button
              type="button"
              className="w-full px-4 py-2 text-sm font-medium text-yellow-500 border  border-yellow-500 rounded-lg hover:bg-yellow-500 hover:text-gray-900 transition-colors duration-200"
              onClick={() => handleRedirect('login')}
            >
              Log In
            </button>
            <button
              type="button"
              className="w-full px-4 py-2 text-sm font-medium bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-colors duration-200"
              onClick={() => handleRedirect('signup')}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

function Achievements() {
  const stats = [
    { id: 'tee-times', icon: Flag, value: '1,300+', label: 'Tee Times Found' },
    { id: 'golfers', icon: Users, value: '30+', label: 'Trusted Golfers' },
    { id: 'availability', icon: Clock, value: '24/7', label: 'Availability' },
    { id: 'success-rate', icon: Check, value: '71%', label: 'Search Success Rate' },
  ];

  return (
    <section className="py-12 mb-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="flex flex-col items-center"
            >
              <div className="bg-yellow-500 rounded-full p-4 mb-4">
                <stat.icon className="w-8 h-8 text-gray-900" />
              </div>
              <span className="text-3xl font-bold text-white mb-2">{stat.value}</span>
              <span className="text-sm text-gray-400 text-center">{stat.label}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function HowItWorks() {
  const steps = [
    {
      step: 1,
      icon: MapPin,
      title: 'Choose Your Course',
      description: 'Select from over 70 supported golf courses',
    },
    {
      step: 2,
      icon: Search,
      title: 'Track Your Search',
      description: 'Monitor active searches in real-time',
    },
    {
      step: 3,
      icon: Bell,
      title: 'Get Notified',
      description: 'Receive instant notifications for available tee times',
    },
    {
      step: 4,
      icon: Check,
      title: 'Secure Tee Time',
      description: 'Quickly book your preferred slot',
    },
  ];

  return (
    <div className="bg-gray-800 rounded-lg py-10 px-8 w-full">
      <div className="relative flex justify-between items-start m-auto max-w-5xl">
        <div
          className="absolute top-9 left-0 right-0 h-1 bg-yellow-500"
          style={{ width: 'calc(100% - 4rem)', margin: '0 2rem' }}
        />
        {steps.map((step) => (
          <div
            key={step.step}
            className="flex flex-col items-center w-1/4 px-4 relative"
          >
            <div className="bg-gray-700 rounded-full p-4 z-10 shadow-lg">
              <step.icon className="w-10 h-10 text-yellow-500" />
            </div>
            <h3 className="mt-4 text-lg font-semibold text-white text-center">
              {step.title}
            </h3>
            <p className="mt-2 text-sm text-gray-400 text-center">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function GetStartedForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    navigate('/signup', { state: { email } });
  };

  return (
    <div className="flex justify-center items-center">
      <form
        className="flex items-center space-x-1"
        onSubmit={handleSubmit}
      >
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-64 pl-9 pr-4 h-10 bg-gray-800 border border-gray-700 rounded-lg
                     focus:outline-none focus:ring-2 focus:ring-yellow-500 text-white"
            required
            disabled={isLoading}
          />
        </div>
        <button
          type="submit"
          className="h-10 px-3 bg-yellow-500 text-gray-900 font-medium rounded-lg
                   hover:bg-yellow-400 transition-colors duration-200
                   disabled:opacity-50 disabled:cursor-not-allowed
                   flex items-center justify-center min-w-[110px]"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              Get Started
              <ArrowRight className="ml-2 w-4 h-4" />
            </>
          )}
        </button>
      </form>
    </div>
  );
}

function Pricing() {
  const navigate = useNavigate();
  return (
    <section className="py-20 scroll-mt-20">
      <h2 className="text-3xl font-bold mb-12 text-center">Simple Pricing</h2>
      <div className="max-w-md mx-auto">
        <div className="bg-gray-800 rounded-lg p-8 border border-gray-700 shadow-xl">
          <div className="text-center">
            <span className="bg-yellow-500/10 text-yellow-500 px-3 py-1 rounded-full text-sm font-medium">
              Monthly Plan
            </span>

            <div className="mt-6 flex items-baseline justify-center">
              <span className="text-5xl font-bold text-white">
                ${process.env.REACT_APP_SUBSCRIPTION_PRICE as string}
              </span>
              <span className="text-gray-400 ml-2">/month</span>
            </div>

            <div className="mt-8 space-y-4">
              <div className="flex items-center justify-center space-x-2 text-gray-300">
                <Check className="w-5 h-5 text-yellow-500" />
                <span>5 concurrent tee time searches</span>
              </div>
              <div className="flex items-center justify-center space-x-2 text-gray-300">
                <Check className="w-5 h-5 text-yellow-500" />
                <span>Real-time availability monitoring</span>
              </div>
              <div className="flex items-center justify-center space-x-2 text-gray-300">
                <Check className="w-5 h-5 text-yellow-500" />
                <span>Instant notifications</span>
              </div>
              <div className="flex items-center justify-center space-x-2 text-gray-300">
                <Check className="w-5 h-5 text-yellow-500" />
                <span>Access to all supported courses</span>
              </div>
            </div>

            <button
              type="button"
              className="mt-8 w-full px-4 py-3 bg-yellow-500 text-gray-900 rounded-lg
                       hover:bg-yellow-400 transition-all font-medium"
              onClick={() => navigate('/signup')}
            >
              Get Started
            </button>

            <p className="mt-4 text-sm text-gray-400">No commitments. Cancel anytime.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

function Landing() {
  const featuresRef = useRef(null);
  const navigate = useNavigate();
  const { courses, getCourses } = useGetCourses();
  // UseEffect to fetch the golf course list
  useEffect(() => {
    if (courses == null) {
      getCourses();
    }
  }, []);
  const smoothScroll = (e: any) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRedirect = (page: string) => {
    navigate(page);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header
        smoothScroll={smoothScroll}
        handleRedirect={handleRedirect}
      />
      <main className="max-w-7xl mx-auto px-12">
        <section className="py-20 text-center">
          <div className="max-w-3xl mx-auto mb-10">
            <span className="inline-block px-3 py-1 mb-4 text-sm font-semibold text-yellow-500 bg-yellow-900 rounded-full">
              Early Access Now Available
            </span>
            <h2 className="text-5xl font-bold mb-6">Secure Coveted Tee Times</h2>
            <p className="text-xl text-gray-300 mb-8">
              ForeAdvantage Golf is your personal assistant to help book the tee time you
              really want. Never miss a chance to play your favorite course again.
            </p>
            <GetStartedForm />
          </div>
          <div className="mt-12">
            <img
              src="images/UIMock.png"
              alt="ForeAdvantage Golf UI Mockup"
              className="px-28 max-w-full md:max-h-l mx-auto rounded-lg"
            />
          </div>
        </section>

        <Achievements />
        <section
          id="supported-courses"
          className="py-20 scroll-mt-20"
        >
          <CheckSupportedCourses />
        </section>
        <section
          id="how-it-works"
          className="py-20 scroll-mt-20"
        >
          <h2 className="text-3xl font-bold mb-12 text-center text-white">How It Works</h2>
          <HowItWorks />
        </section>

        <section
          id="features"
          ref={featuresRef}
          className="py-20 scroll-mt-20"
        >
          <h2 className="text-3xl font-bold mb-12 text-center">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard
              icon={Search}
              title="Advanced Course Search"
              description="Find the perfect course among our selection of over 70 supported golf courses."
            />
            <FeatureCard
              icon={Calendar}
              title="Flexible Scheduling"
              description="Find tee times as far out as permitted by the course or snag last-minute openings."
            />
            <FeatureCard
              icon={Clock}
              title="Real-time Availability"
              description="Receive instant notifications when your desired tee times become available."
            />
            <FeatureCard
              icon={Users}
              title="Keep Your Group Happy"
              description="Get a time that works for everyone, even when schedules are tight."
            />
          </div>
        </section>
      </main>
      <section
        id="pricing"
        className="py-20 scroll-mt-20"
      >
        <Pricing />
      </section>
      <footer className="bg-black py-8 mt-20 text-center text-gray-400">
        <div className="max-w-7xl mx-auto px-4">
          <span className="flex items-center justify-center space-x-2 mb-4">
            <FaGithub className="text-xl" />
            <a
              href="https://github.com/cgorski03"
              className="underline text-gray-400 hover:text-gray-300 transition-colors duration-200"
            >
              github.com/cgorski03
            </a>
          </span>
          <p>
            <a
              href="#dummy"
              className="text-yellow-500 hover:underline"
            >
              Terms of Service
            </a>{' '}
            |
            <a
              href="#dummy"
              className="text-yellow-500 hover:underline ml-2"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
