import React, { useContext } from 'react';
import { UserInformationContext } from '../../Contexts/UserContext';

interface SubscriptionRequiredProps {
  children: React.ReactNode;
}

function SubscriptionRequired({
  children,
}: SubscriptionRequiredProps): React.JSX.Element | null {
  const { userInfo } = useContext(UserInformationContext);
  if (!userInfo) {
    return null;
  }

  if (userInfo.subscriptionStatus !== 'active') {
    const getWarningMessage = () => {
      if (!userInfo.subscriptionStatus) {
        return 'These features are not available without a subscription.';
      }
      switch (userInfo.subscriptionStatus) {
        case 'incomplete':
          return 'Your last payment attempt failed.';
        case 'canceled':
          return 'Your subscription has been canceled.';
        default:
          return 'Your subscription has expired.';
      }
    };

    return (
      <>
        {/* Prevent interaction overlay */}
        <div className="fixed inset-0 bg-gray-900/50 z-40" />

        {/* Toast notification */}
        <div
          className="fixed bottom-6 left-1/2 -translate-x-1/2 z-50
                      bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg
                      flex items-center space-x-3 max-w-md w-full mx-4"
        >
          <div className="w-2 h-2 rounded-full bg-white animate-pulse flex-shrink-0" />
          <p className="text-sm font-medium">
            {getWarningMessage()}{' '}
            <a
              href="/settings"
              className="underline hover:text-white/90"
            >
              Manage subscription
            </a>
          </p>
        </div>

        {/* Content with reduced opacity */}
        <div className="pointer-events-none opacity-50">{children}</div>
      </>
    );
  }

  return <div>{children}</div>;
}

export default SubscriptionRequired;
