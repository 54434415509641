/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { FaUser, FaCreditCard, FaCog } from 'react-icons/fa';
import { RefreshCcw } from 'lucide-react';
import { UserInformationContext } from '../../Contexts/UserContext';
import UserContactInformationField from '../../components/settings/UserContactInformationField';
import Spinner from '../../components/utils/Spinner';
import {
  useCreateCheckoutSession,
  useCreateManageBillingSession,
  useSyncStripeData,
} from '../../utils/api/stripe-requests';
import useAuthContext from '../../utils/hooks/useAuthContext';

type SettingsTab = 'profile' | 'billing' | 'account';

function Settings() {
  const [activeTab, setActiveTab] = useState<SettingsTab>('profile');
  const { userInfo } = useContext(UserInformationContext);
  const [preferences, setPreferences] = useState({
    enableArtifacts: true,
    enablePromptExamples: true,
    enableCSV: true,
  });
  const { createCheckoutSession, checkoutLink, checkoutLoading } =
    useCreateCheckoutSession();
  const { createManageBillingSession, manageLink, manageBillingLoading } =
    useCreateManageBillingSession();
  const { syncStripeData, syncLoading } = useSyncStripeData();
  const { forceAuthRefresh, authContextLoading } = useAuthContext();
  if (!userInfo) return <Spinner />;

  const handleBillingButtonClick = async () => {
    if (manageBillingLoading || checkoutLoading) return;
    if (userInfo.subscriptionStatus === 'active') {
      await createManageBillingSession();
    } else {
      await createCheckoutSession();
    }
  };

  useEffect(() => {
    if (checkoutLink) {
      window.location.href = checkoutLink.url;
    }
  }, [checkoutLink]);

  useEffect(() => {
    if (manageLink) {
      window.location.href = manageLink.url;
    }
  }, [manageLink]);

  const generateButtonText = () =>
    userInfo?.subscriptionStatus === 'active' ? 'Manage Plan' : 'Subscribe';

  const generateSubscriptionText = () => {
    console.log(userInfo);
    if (
      userInfo?.subscriptionStatus === 'active' &&
      userInfo?.cancelAtSubscriptionEnd === 'false'
    ) {
      return `Renews ${new Date(
        Number(userInfo.subscribedUntil!) * 1000,
      ).toLocaleDateString()}`;
    }
    if (
      userInfo?.subscriptionStatus === 'active' &&
      userInfo?.cancelAtSubscriptionEnd === 'true'
    ) {
      return `Access will continue until ${new Date(
        Number(userInfo.subscribedUntil!) * 1000,
      ).toLocaleDateString()} and will not renew`;
    }
    return 'No active subscription';
  };

  const syncBillingInformation = async () => {
    await syncStripeData();
    await forceAuthRefresh();
  };
  function renderTabContent() {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-6">
            <div>
              <h2 className="text-lg font-semibold text-white mb-4">Profile Information</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white"
                    defaultValue={userInfo?.name}
                  />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-semibold text-white mb-4">Preferences</h2>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-white font-medium">Sample settings</h3>
                    <p className="text-gray-400 text-sm">
                      I actually cant think of a single setting that would be useful but
                      they will be built out here
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={preferences.enableArtifacts}
                      onChange={(e) =>
                        setPreferences((p) => ({ ...p, enableArtifacts: e.target.checked }))
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-500/25 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-500" />
                  </label>
                </div>
              </div>
            </div>

            <h2 className="text-lg font-semibold text-white mb-4">Notification Settings</h2>
            <UserContactInformationField />
          </div>
        );

      case 'billing':
        return (
          <section className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <h2 className="text-lg font-semibold text-white">Paid Subscription</h2>
                <p className="text-sm text-gray-400">{generateSubscriptionText()}</p>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={syncBillingInformation}
                  type="button"
                  disabled={syncLoading || authContextLoading}
                  className="text-gray-400 hover:text-white transition-colors disabled:opacity-75 disabled:hover:text-gray-400 disabled:animate-spin"
                  aria-label="Refresh billing information"
                  title="Attempt refresh if this looks incorrect"
                >
                  <RefreshCcw className="h-5 w-5" />
                </button>
                <button
                  onClick={handleBillingButtonClick}
                  type="button"
                  disabled={manageBillingLoading || checkoutLoading}
                  className="px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-colors font-medium min-w-[120px] min-h-[40px] flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed"
                >
                  {manageBillingLoading || checkoutLoading ? (
                    <Spinner />
                  ) : (
                    generateButtonText()
                  )}
                </button>
              </div>
            </div>
          </section>
        );

      case 'account':
        return (
          <div className="space-y-6">
            <h2 className="text-lg font-semibold text-white mb-4">Account Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white"
                  value={userInfo?.email}
                  disabled
                />
              </div>
              <button
                type="button"
                className="px-4 py-2 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors font-medium"
              >
                Delete Account
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <h2 className="text-lg font-semibold text-white mb-4">Account Settings</h2>
          </div>
        );
    }
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8 mt-16">
      <div className="max-w-6xl mx-auto">
        <div className="flex gap-8 items-start">
          {/* Sidebar */}
          <div className="w-64">
            <div className="bg-gray-800 rounded-lg p-4 mb-4">
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 rounded-full bg-yellow-500 flex items-center justify-center">
                  {userInfo.picture ? (
                    <img
                      src={userInfo.picture}
                      alt={userInfo.name}
                      className="w-10 h-10 rounded-full"
                    />
                  ) : (
                    <span className="text-gray-900 font-bold">{userInfo.name?.[0]}</span>
                  )}
                </div>
                <div>
                  <h3 className="text-white font-medium">{userInfo.name}</h3>
                  <p className="text-gray-400 text-sm">{userInfo.email}</p>
                </div>
              </div>
            </div>
            <nav className="space-y-1">
              <button
                type="button"
                onClick={() => setActiveTab('profile')}
                className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'profile'
                    ? 'bg-yellow-500 text-gray-900'
                    : 'text-gray-300 hover:bg-gray-800'
                }`}
              >
                <FaUser />
                <span>Profile</span>
              </button>
              <button
                type="button"
                onClick={() => setActiveTab('billing')}
                className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'billing'
                    ? 'bg-yellow-500 text-gray-900'
                    : 'text-gray-300 hover:bg-gray-800'
                }`}
              >
                <FaCreditCard />
                <span>Billing</span>
              </button>
              <button
                type="button"
                onClick={() => setActiveTab('account')}
                className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'account'
                    ? 'bg-yellow-500 text-gray-900'
                    : 'text-gray-300 hover:bg-gray-800'
                }`}
              >
                <FaCog />
                <span>Account</span>
              </button>
            </nav>
          </div>

          {/* Main content */}
          <div className="flex-1 bg-gray-800 rounded-lg p-6">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
