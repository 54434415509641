import React, { useState } from 'react';
import { signUp, signInWithRedirect } from 'aws-amplify/auth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Eye, EyeOff, ArrowLeft, Mail } from 'lucide-react';
import { FcGoogle } from 'react-icons/fc';
import { Turnstile } from '@marsidev/react-turnstile';
import Spinner from '../../components/utils/Spinner';

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState<'email' | 'details'>('email');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: location.state?.email || '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });

  const handleSocialSignIn = async (chosenProvider: 'Google' | 'Apple') => {
    try {
      await signInWithRedirect({ provider: chosenProvider });
    } catch (err: any) {
      console.log(err);
      setError(`Error signing in with ${chosenProvider}`);
    }
  };

  const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formElement = e.currentTarget;
    const turnstileResponse = formElement['cf-turnstile-response']?.value;

    if (!turnstileResponse) {
      setError('Please complete the verification'); /*  */
      return;
    }
    setStep('details');
  };

  const handleDetailsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    try {
      await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            email: formData.email,
            name: `${formData.firstName} ${formData.lastName}`,
          },
        },
      });
      navigate('/confirm-email', {
        state: {
          email: formData.email,
          mode: 'signup',
        },
      });
    } catch (err: any) {
      setError(err.message || 'An error occurred during signup');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <header className="bg-black py-4 px-6 sticky top-0 shadow-lg">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/"
            className="flex items-center group"
          >
            <ArrowLeft className="w-5 h-5 text-yellow-500 mr-2 transition-transform group-hover:-translate-x-1" />
            <span className="text-yellow-500 text-sm">Back to Home</span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-sm border border-gray-700">
          <div className="flex items-center justify-center mb-6">
            <img
              src="golf_bot_image.jpeg"
              alt="ForeAdvantage Golf Logo"
              className="w-12 h-12 rounded-full border-2 border-yellow-500"
            />
          </div>

          <h1 className="text-2xl font-bold text-white text-center mb-6">
            {step === 'email' ? 'Create Your Account' : 'Complete Your Profile'}
          </h1>

          {error && (
            <div className="bg-red-500/10 border border-red-500/50 text-red-500 px-4 py-3 rounded-lg mb-6 text-sm">
              {error}
            </div>
          )}

          {step === 'email' ? (
            <form
              onSubmit={handleEmailSubmit}
              className="space-y-4"
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Email Address
                  <div className="relative mt-1">
                    <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500" />
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="w-full pl-9 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 outline-none text-white"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="you@example.com"
                    />
                  </div>
                </label>
              </div>

              <div className="flex justify-center">
                <Turnstile siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY as string} />
              </div>

              <button
                type="submit"
                className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium"
              >
                Continue with Email
              </button>

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-600" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-800 text-gray-400">or continue with</span>
                </div>
              </div>

              <div className="space-y-3">
                <button
                  type="button"
                  onClick={() => handleSocialSignIn('Google')}
                  className="w-full px-4 py-2 bg-white hover:bg-gray-100 text-gray-900 rounded-lg transition-colors flex items-center justify-center space-x-2"
                >
                  <FcGoogle className="w-5 h-5" />
                  <span className="font-medium">Google</span>
                </button>
              </div>
            </form>
          ) : (
            <form
              onSubmit={handleDetailsSubmit}
              className="space-y-4"
            >
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    First Name
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      required
                      className="w-full px-3 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Last Name
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      required
                      className="w-full px-3 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Password
                  <div className="relative mt-1">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      required
                      className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white pr-10"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                    </button>
                  </div>
                </label>
              </div>

              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Confirm Password
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    className="w-full px-3 py-2 mt-1 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-yellow-500 text-white"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="••••••••"
                  />
                </label>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full px-4 py-2 bg-yellow-500 text-gray-900 rounded-lg hover:bg-yellow-400 transition-all font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              >
                {isLoading ? <Spinner /> : 'Create Account'}
              </button>
            </form>
          )}

          <p className="mt-6 text-center text-gray-400 text-sm">
            Already have an account?{' '}
            <Link
              to="/login"
              className="text-yellow-500 hover:text-yellow-400"
            >
              Log in
            </Link>
          </p>
        </div>
      </main>

      <footer className="bg-black py-4 text-center text-gray-400">
        <p className="text-sm">
          By signing up, you agree to our{' '}
          <Link
            to="/terms"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy"
            className="text-yellow-500 hover:text-yellow-400"
          >
            Privacy Policy
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default Signup;
