import React, { useContext, useState } from 'react';
import { FaDiscord } from 'react-icons/fa';
import { UserInformationContext } from '../../Contexts/UserContext';
import { useTestMessage } from '../../utils/api/requests';
import DiscordVerificationPopup from './DiscordVerificationPopup';
import Spinner from '../utils/Spinner';

function UserContactInformationField() {
  const { userInfo } = useContext(UserInformationContext);
  const { sendTestMessage, testMessageLoading } = useTestMessage();
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);

  return (
    <section className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-lg font-semibold text-white">Discord Integration</h2>
          <div className="flex items-center space-x-2">
            <span
              className={`inline-block w-2 h-2 rounded-full ${
                userInfo?.channelId ? 'bg-green-500' : 'bg-gray-500'
              }`}
            />
            <span className="text-gray-300">
              {userInfo?.channelId ? 'Connected' : 'Not Connected'}
            </span>
          </div>
        </div>

        {userInfo?.channelId ? (
          <button
            type="button"
            onClick={() => sendTestMessage()}
            disabled={testMessageLoading}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors font-medium flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FaDiscord className="w-4 h-4 text-[#5865F2]" />
            {testMessageLoading ? (
              <div className="flex items-center space-x-2">
                <Spinner />
                <span>Testing...</span>
              </div>
            ) : (
              <span>Test Connection</span>
            )}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setShowVerifyPopup(true)}
            className="px-4 py-2 bg-[#5865F2] text-white rounded-lg hover:bg-[#4752C4] transition-colors font-medium flex items-center space-x-2"
          >
            <FaDiscord className="w-4 h-4" />
            <span>Connect Discord</span>
          </button>
        )}
      </div>

      {!userInfo?.channelId && (
        <p className="text-sm text-gray-400 mt-4">
          Connect your Discord account to receive tee time notifications and updates.
        </p>
      )}

      {showVerifyPopup && (
        <DiscordVerificationPopup closeWindow={() => setShowVerifyPopup(false)} />
      )}
    </section>
  );
}

export default UserContactInformationField;
